import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';

const AboutYouAndYourPet: React.FC = () => {
  useEffect(() => {
    navigate(quoteAndBuyRoutes.aboutYourPet);
  }, []);

  return null;
};

export default AboutYouAndYourPet;
